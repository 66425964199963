import { Options, Vue } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'

@Options({
  components: {
    PagePlugin
  }
})

export default class AchievementManage extends SuperVue {

  /**
   * 搜索条件
   */
  searchData = {
    name: ''
  }

  /**
   * 表格
   */
   tableData = [
     {
       name: '一个关于如何提升燃油经济性的技术',
       upload_time: '2021/09.10',
       author: '张三',
       evaluate_status: '待评估',
       finish_time: '2021/09/12',
       score: '8.5'
     },
     {
       name: '一个关于如何提升燃油经济性的技术',
       upload_time: '2021/09.10',
       author: '张三',
       evaluate_status: '待评估',
       finish_time: '2021/09/12',
       score: '8.5'
     }
   ]

   /**
    * 分页
    */
   total = 100
   getTdata (offset:number, length:number):void {
     console.log(offset, length)
   }
   showDialog = false
}
